import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { createGlobalState } from 'react-hooks-global-state';
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { gsap, wrapYoyo } from 'gsap';
import qs from 'qs';
import axios from 'axios';

import Navigation from './navigation';

import styles from '../styles/appointment.module.scss'; 
import { setMaxListeners } from 'process';
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg';  


const Appointment=(props: any)=> {
    const [change, setChange] = useState(true);
    const [mailValue, sentMail] = useState(false);
    const urlParams = useParams();
    const arrowRef = useRef();
    const sectionVar = props.section;

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 2, y: -40, repeat: -1, yoyo: true}); 
    },[]);

    const handleSubmit=(event: any)=> {
        event.preventDefault();

        const user = {
            to: "michael.argast@sense-and-image.com",
            subject: "DEV TEST - Anfrage",
            text: "Eine neue Projektanfrage"
    };
    
        const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        auth: { username: 'admin', password: 'supersecret' },
        data: qs.stringify(user),
        url: 'https://jahresbericht-nodemailer.azurewebsites.net/v1/text-mail',
        };

        {/*// @ts-ignore */}
        axios(options).then(res => {
            console.log(res);
            console.log(res.data);
            sentMail(true)
          })
    }
    
        return (
            <div className={styles.overlayContainer}>
                {/*// @ts-ignore */}
                <div style={{position: 'absolute', right: '0px', bottom: '0px', width: '40px', zIndex: '999999999999'}}><ArrowSVG ref={arrowRef} style={{filter: 'invert(0%) sepia(100%) saturate(29%) hue-rotate(119deg) brightness(103%) contrast(106%)'}}  /></div>
                <div className={styles.iframeContainer}>
                     { /*{props.section} Appointment*/ }
                    
                    <iframe id="contactFrame" src={i18next.t('appointment.contactFormExternal')} className={styles.iframe} />

                    { /*<div className={!mailValue? styles.show: styles.hide}>
                         
                        <form onSubmit={handleSubmit}>
                            <label>
                                Test:
                                <input type="text" name="name" />
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>

                    <div className={mailValue? styles.show: styles.hide}>
                       Anfrage gesendet!
                    </div>*/ }

                </div>
            </div>
            );
    }
    
export default withTranslation()(Appointment);
    