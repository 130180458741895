import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation, Translation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { gsap } from 'gsap';

import Navigation from './navigation';
import tracking from './tracking/tracking'; 
import Social from './social'; 

import {ReactComponent as StartSVG} from '../assets/images/start.svg';  
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg';  
import styles from '../styles/start.module.scss'; 
import '../styles/fonts.scss'; 


const Start=(props: any)=> {
    const [change, setChange] = useState(true);
    const urlParams = useParams();
    const arrowRef = useRef();
    const section = "Black";

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true});
        
        gsap.timeline()
          .from("#Waende_start, #Kartonregal_start, #Kartonstapel_start, #Kabelrollen_Wand_start", { y: '-350px', opacity: 0, stagger: 0.2, delay: 0.5})
          .from("#Auto_start, #Hund_start, #Pflanze_3_start, #Arbeitstische_start, #Bildschirm_links_start, #Elemente_linke_Ecke_start", { x: '-350px', opacity: 0, stagger: 0.5, delay: 0.5 })
          .from("#Person_mit_Brille_start, #Werkzeugecke_links_start, #Werkzeugkasten_start, #Scannende_Person_start, #Roboter_start, #Solarpanelen_start, #Ladestation_start", { y: '350px', opacity: 0, stagger: 0.2 })
    },[]);


    const navigateDown = i18next.t('navigation.0.route');

        return (
            <div className={`${styles.backgroundStart}`}>

            <div className={styles.content}>    
            <div className={styles.contentTextBlock} id="start">
                <div className={styles.sectionDescription}>
                    <h1 className={styles.sectionDescriptionHeadline} dangerouslySetInnerHTML={{__html: i18next.t('start.title', {interpolation: {escapeValue: false}})}}></h1>
                    <div className={styles.sectionDescriptionText} dangerouslySetInnerHTML={{__html: i18next.t('start.description', {interpolation: {escapeValue: false}})}}>
                    
                    </div>
                </div>
                <div className={styles.sectionImage} id="section-trigger_start">
                    <StartSVG />
                </div>
            </div>
            <div className={styles.btnDown}>
                <div>
                {/*// @ts-ignore */}
                <HashLink onClick={() => { tracking('arrowDownStart') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                </div>
                <div>
                    {/*// @ts-ignore */}
                    <Social section={section} />
                </div>
            </div>
            </div>
            
            </div>
            
            );
    }
    
export default withTranslation()(Start);
    