import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger } from 'react-gsap';
import Navigation from './navigation';
import tracking from './tracking/tracking'; 
import Appointment from './appointment';
import { gsap } from 'gsap';
import { SVGUniqueID } from 'react-svg-unique-id'

import styles from '../styles/elektronik.module.scss'; 
import main from '../styles/main.module.scss'; 
import {ReactComponent as ElektronikSVG} from '../assets/images/042_Elektronik.svg';
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg'; 

function ToggleOverlay() {
    const [overlayValue, setOverlay] = useState({overlayStatus: PlayState.play});

    return overlayValue
}

function ExternalLink(url:any) {
    window.open(url, '_blank')
}

const Elektronik=()=> {
    const [change, setChange] = useState(true);
    const [overlayValue, setOverlay] = useState(false);
    const [appointmentOverlay, setAppointment] = useState(false);
    const urlParams = useParams();
    const arrowRef = useRef();

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true}); 
    },[]);

    useLayoutEffect(()=> {
        gsap.timeline({
            scrollTrigger: {
              trigger: "#section-trigger_elektronik",
              start: "center bottom-=100px",
              end: "center-=100px center",
              scrub: 3,
               //markers: {startColor: "green", endColor: "red", fontSize: "12px"}
            }
          })
          .from("#Wand_links, #Ebene_28_Kopieren_3, #Ebene_28_Kopieren_2, #Tisch_rechts, #Werkzeugecke_links, #Auto, #Glasfront-2", { y: '-350px', opacity: 0, stagger: 0.2, delay: 2})
          .from("#Unimog_elektronik, #Mitarbeiter, #Elemente_hinten_links, #Material_Wand_rechts, #Tisch_links", { x: '-350px', opacity: 0, stagger: 0.5, delay: 2 })
          .from("#Ladestationen, #Autowerkstatt_links", { y: '350px', opacity: 0, stagger: 0.2 })
    }, []);

    const navigateDown = i18next.t('anchors.2.route');

        return (
            <div className={`${styles.backgroundSolutions}`} style={{overflow: 'hidden'}}>


        <div className={styles.content}>    
            <div className={styles.contentTextBlock} id="start">
                <div className={styles.sectionDescription}>
                    <h1 className={styles.sectionDescriptionHeadline} dangerouslySetInnerHTML={{__html: i18next.t('elektronik.title', {interpolation: {escapeValue: false}})}}></h1>
                    <div className={styles.sectionDescriptionText}>
                        <div dangerouslySetInnerHTML={{__html: i18next.t('elektronik.description', {interpolation: {escapeValue: false}})}}></div>
                        
                    </div>
                    
                </div>
                <div className={styles.sectionImage} id="section-trigger_elektronik">
                    
                <SVGUniqueID>
                    <ElektronikSVG />
                </SVGUniqueID>

                </div>
            </div>
            <div className={styles.sectionNavigation}>
                        <div className={styles.btnBouncing}>
                            {/*// @ts-ignore */}
                            <HashLink onClick={() => { tracking('arrowDownElektronik') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                        </div>
                        <div className={styles.sectionNavigationRight}>
                            <div>
                                <div>
                                    <a href={i18next.t('elektronik.link')} onClick={() => { tracking('elektronikLink') }} target="_blank" className={styles.actionBtn}>{ i18next.t('elektronik.button') }</a>
                                
                                </div>
                            </div>
                            <div>
                                <div>
                                    <button className={styles.actionBtn} onClick={() => { setAppointment(true); tracking('elektronikTermin') }} >{ i18next.t('elektronik.buttonAppointment') }</button>
                                </div>
                            </div>
                        </div>
                </div>

                <div className={`${appointmentOverlay? styles.show: styles.hide} ${main.appointmentContainer}`}>
                            <div className={main.appointmentCloseButtonContainer}>
                                <button className={styles.closeBtn} onClick={() => { setAppointment(false) }} ><span className={main.appointmentCloseButton}>X</span></button>
                            </div>
                            <Appointment section="Consulting" />
                </div>
            
            </div>


            
                <Tween
                    playState={overlayValue? PlayState.play: PlayState.reverse}
                    to={{
                    x: '-100%',
                    y: '0px',
                    ease: 'power2.inOut',
                    duration: 1
                    }}
                >
                    <div className={styles.overlayContainer} style={{position: 'absolute', top: '0', right: '-100%', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: '99999999999', display: 'block'}}>

                        <div className={styles.overlayHeadlineContainer}>
                                <div className={styles.overlayHeadline}>
                                    Logistik
                                </div>
                                <div className={styles.padding15}>
                                    <button className={styles.overlayOutLink} >Zur Website</button>
                                </div>
                        </div>
                        <div className={styles.overlayText}>
                            <div className={styles.overlayTextLeft}>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                            </div>
                            <div className={styles.overlayTextRight}>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                            </div>
                        </div>
                        <div className={styles.overlayCloseContainer}>
                            <div className={styles.closeOverlayBtn}>
                                <button onClick={() => { setOverlay(false) }} style={{display: 'inline-block', background: 'none', padding: '5px 14px 5px 14px', border: '2px solid #fff', color: '#fff', fontFamily: 'Monserrat-SemiBold', fontSize: '12px', cursor: 'pointer'}} >Schließen</button>
                            </div>
                        </div>
                    </div>

                </Tween>

            </div>
            );
    }
    
export default withTranslation()(Elektronik);
    