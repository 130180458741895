import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

import Navigation from './navigation';
import tracking from './tracking/tracking'; 

import styles from '../styles/footer.module.scss';
import '../styles/fonts.scss';  

import Social from './social'; 



const Footer=()=> {
    const [change, setChange] = useState(true);
    const urlParams = useParams();
    const section = "";

    const footerNavigation: { id: number, name: string, external: string }[] = i18next.t('footerNavigation', {returnObjects:true});

        return (
            <div className={`${styles.backgroundFooter} ${styles.boxShadow}`}>
                <div className={styles.footer} id="footer">
     
                <div className={styles.footerLinks}>
                {
                    footerNavigation.map(({id, name, external, trackingId}: any) => <a href={external} onClick={() => { tracking(trackingId) }} target="_blank" key={id} style={{padding: '10px', color: '#FFF'}} >{name}</a>)

                }
                </div>
                <div>
                    <Social section={section} />
                </div>
    
                </div>
            </div>
            );
    }
    
export default withTranslation()(Footer);
    