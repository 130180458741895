import TagManager from 'react-gtm-module';

const tracking=(event: any)=> {
    console.log(event);

    const tagManagerArgs = {
        gtmId: 'GTM-PRM9G5K',
        dataLayer: {
            event: 'Star-Jahresbericht-2021',
            eventCategory: 'Jahresbericht',
            eventAction: event,
            config: 'GTM-PRM9G5K'
        }
    }

    TagManager.dataLayer(tagManagerArgs);
}
   
export default tracking;
    