import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger } from 'react-gsap';
import Navigation from './navigation';
import Appointment from './appointment';
import tracking from './tracking/tracking'; 
import { gsap } from 'gsap';

import {ReactComponent as AllgemeinSVG} from '../assets/images/allgemein.svg';
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg';   
import styles from '../styles/sustainability.module.scss'; 
import main from '../styles/main.module.scss'; 

function ToggleOverlay() {
    const [overlayValue, setOverlay] = useState({overlayStatus: PlayState.play});

    return overlayValue
}

function ExternalLink(url:any) {
    window.open(url, '_blank')
}

const Sustainability=()=> {
    const [change, setChange] = useState(true);
    const [overlayValue, setOverlay] = useState(false);
    const [appointmentOverlay, setAppointment] = useState(false);
    const urlParams = useParams();
    const arrowRef = useRef();

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true}); 
    },[]);

    useLayoutEffect(()=> {
        gsap.timeline({
            scrollTrigger: {
              trigger: "#section-trigger_sustainability",
              start: "center bottom-=100px",
              end: "center-=100px center",
              scrub: 3,
            //markers: {startColor: "green", endColor: "red", fontSize: "12px"}
            }
          })
          .from("#Waende_allgemein, #Wand_links_vorne_allgemein, #Kueche_allgemein", { y: '-350px', opacity: 0, stagger: 0.2, delay: 2})
          .from("#Dach_allgemein, #Sitzgruppe_1_allgemein, #Pflanze_allgemein, #Hund_allgemein", { x: '-350px', opacity: 0, stagger: 0.5, delay: 2 })
          .from("#Brunnen_allgemein, #Bildschirm_engineering, #Tischtennisplatte_allgemein, #Bienenszene_allgemein, #Dartscheibe_allgemein", { y: '350px', opacity: 0, stagger: 0.2 })
    }, []);

    const navigateDown = i18next.t('anchors.10.route');

        return (
            <div className={`${styles.backgroundSolutions}`} style={{overflow: 'hidden'}}>


        <div className={styles.content}>    
            <div className={styles.contentTextBlock} id="start">
                <div className={styles.sectionDescription}>
                    <h1 className={styles.sectionDescriptionHeadline} dangerouslySetInnerHTML={{__html: i18next.t('career.title', {interpolation: {escapeValue: false}})}}></h1>
                    <div className={styles.sectionDescriptionText}>
                        <div dangerouslySetInnerHTML={{__html: i18next.t('career.description', {interpolation: {escapeValue: false}})}}></div>
                        
                    </div>
                </div>
                <div className={styles.sectionImage} id="section-trigger_sustainability">

                    <AllgemeinSVG />

                </div>
            </div>
            <div className={styles.sectionNavigation}>
                        <div className={styles.btnBouncing}>
                            {/*// @ts-ignore */}
                            <HashLink onClick={() => { tracking('arrowDownNachhaltigkeit') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                        </div>
                        <div className={styles.sectionNavigationRight}>
                            <div>
                                <div>
                                    <button className={styles.actionBtn} onClick={() => { ExternalLink(i18next.t('career.link')); tracking('nachhaltigkeitsberichtLink') }} >{ i18next.t('career.button') }</button>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <button className={styles.actionBtn} onClick={() => { setAppointment(true); tracking('nachhaltigkeitsberichtTermin') }} >{ i18next.t('career.buttonAppointment') }</button>
                                </div>
                            </div>
                        </div>
            </div>

            <div className={`${appointmentOverlay? main.show: main.hide} ${main.appointmentContainer}`}>
                    <div className={main.appointmentCloseButtonContainer}>
                        <button className={main.closeBtn} onClick={() => { setAppointment(false) }} ><span className={main.appointmentCloseButton}>X</span></button>
                    </div>
                    <Appointment section="Sustainabillity" />
            </div>
            
            </div>

            </div>
            );
    }
    
export default withTranslation()(Sustainability);
    