import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger } from 'react-gsap';
import Navigation from './navigation';
import tracking from './tracking/tracking'; 
import { gsap } from 'gsap';

import styles from '../styles/report2020.module.scss'; 
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg'; 

function ToggleOverlay() {
    const [overlayValue, setOverlay] = useState({overlayStatus: PlayState.play});

    return overlayValue
}

function ExternalLink(url:any) {
    window.open(url, '_blank')
}

const Report2020=()=> {
    const [change, setChange] = useState(true);
    const [overlayValue, setOverlay] = useState(false);
    const [appointmentOverlay, setAppointment] = useState(false);
    const urlParams = useParams();
    const arrowRef = useRef();
    const reportRef = useRef();

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1.5, y: 20, repeat: -1, yoyo: true}); 
        //gsap.to([reportRef.current], {duration: 25, y: 0, x: -600, ease: 'none', repeat: -1, yoyo: true}); 
    },[]);

    const navigateUp = i18next.t('#start');

        return (
            <div className={`${styles.backgroundSolutions}`} style={{overflow: 'hidden'}}>


        <div className={styles.content}>    
            <div className={styles.contentTextBlock} id="start">
                <div className={styles.sectionDescription}>
                    <h1 className={styles.sectionDescriptionHeadline} dangerouslySetInnerHTML={{__html: i18next.t('report2020.title', {interpolation: {escapeValue: false}})}}></h1>
                    <div className={styles.sectionDescriptionText}>
                    <div dangerouslySetInnerHTML={{__html: i18next.t('report2020.description', {interpolation: {escapeValue: false}})}}></div>
                    
                    </div>
                    <div style={{paddingTop: '20px'}}>
                        <a href={i18next.t('report2020.link')} onClick={() => { tracking('report2020') }} target="_blank" className={styles.actionBtn}>{ i18next.t('report2020.button') }</a>
                    </div>
                    
                </div>
                
                <div className={styles.sectionImage}>

                    <div style={{maxWidth: '720px', maxHeight: '350px', overflow: 'hidden', borderRadius: '12px'}}>
                        
                        {/*// @ts-ignore */}
                        <video loop="loop" width="720" height="auto" autoPlay muted>
                            <source src={require('../assets/videos/Jahresbericht_2020_small.mp4')} type="video/mp4" style={{height: '300px'}} />
                        
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </div>
            <div className={styles.sectionNavigation}>
                        <div className={styles.btnBouncing}>
                            {/*// @ts-ignore */}
                            <HashLink onClick={() => { tracking('arrowUp') }} smooth to={navigateUp} ><ArrowSVG ref={arrowRef} className={styles.arrowUp} /></HashLink>
                            
                        </div>
                        
            </div>
            
            </div>



            </div>
            );
    }
    
export default withTranslation()(Report2020);
    