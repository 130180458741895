import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger } from 'react-gsap';
import Navigation from './navigation';
import tracking from './tracking/tracking'; 
import { gsap } from 'gsap';
import Pagination from '@mui/material/Pagination';

import {ReactComponent as ExpertsSVG} from '../assets/images/expertenfelder.svg';  
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg'; 
import styles from '../styles/experts.module.scss'; 

function ExternalLink(url:any) {
    window.open(url, '_blank')
}

const Experts=()=> {
    const [change, setChange] = useState(true);
    const [overlayValue, setOverlay] = useState(false);
    const [appointmentOverlay, setAppointment] = useState(false);
    const [page, setPage] = React.useState(1);
    const urlParams = useParams();
    const arrowRef = useRef();

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true}); 
    },[]);

    useLayoutEffect(()=> {
        gsap.timeline({
            scrollTrigger: {
              trigger: "#section-trigger_experts",
              start: "center bottom-=100px",
              end: "center-=100px center",
              scrub: 3,
            //markers: {startColor: "green", endColor: "red", fontSize: "12px"}
            }
          })
          .from("#Waende_experts, #Kartonregal_experts, #Kartonstapel_experts, #Kabelrollen_Wand_experts", { y: '-350px', opacity: 0, stagger: 0.2, delay: 2})
          .from("#Auto_experts, #Hund_experts, #Pflanze_3_experts, #Arbeitstische_experts, #Bildschirm_links_experts, #Elemente_linke_Ecke_experts", { x: '-350px', opacity: 0, stagger: 0.5, delay: 2 })
          .from("#Person_mit_Brille_experts, #Werkzeugecke_links_experts, #Werkzeugkasten_experts, #Scannende_Person_experts, #Roboter_experts, #Solarpanelen_experts, #Ladestation_experts", { y: '350px', opacity: 0, stagger: 0.2 })
    }, []);

    const handleChange = (event: any, value: any) => {
        setPage(value);
        console.log(value);
    };

    const changeTopic = (direction: any) => {
        if(direction == 'forward') {
            if(page != 3) {
                setPage(page+1);
                console.log(page+1);
            } else {
                setPage(1);
            }
        } else {
            if(page != 1) {
                setPage(page-1);
                console.log(page-1);
            } else {
                setPage(3);
            }
        }

        if(page == 1) {
            tracking('digitalisierung')
        } else if(page == 2) {
            tracking('fahrzeugelektronik')
        } else if(page == 3) {
            tracking('aftersales')
        }
    };

    const navigateDown = i18next.t('anchors.8.route');

        return (
            <div className={`${styles.backgroundExperts}`}>

            <div className={styles.content}>    
            <div className={styles.contentTextBlock} id="experts">
                <div className={styles.sectionImage} id="section-trigger_experts">
                    <ExpertsSVG />
                </div>
                <div className={styles.sectionDescription}>
                    <h1 className={styles.sectionDescriptionHeadline} dangerouslySetInnerHTML={{__html: i18next.t('experts.title', {interpolation: {escapeValue: false}})}}></h1>
                    <div className={styles.sectionDescriptionText}>
                        <div dangerouslySetInnerHTML={{__html: i18next.t('experts.description', {interpolation: {escapeValue: false}})}}></div>
                        
                    </div>
                </div>
            </div>
            <div className={styles.sectionNavigation}>
                        <div className={styles.btnBouncing}>
                            {/*// @ts-ignore */}
                            <HashLink onClick={() => { tracking('arrowDownExperten') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                        </div>
                        <div className={styles.sectionNavigationRight}>
                            <div>
                                <div>
                                    <button className={styles.actionBtn} onClick={() => { setOverlay(true); tracking('expertenLesen'); tracking('aftersales') }} >{ i18next.t('experts.buttonMore') }</button>
                                </div>
                            </div>
                        </div>
                </div>
            
            </div>




            <Tween
                    playState={overlayValue? PlayState.play: PlayState.reverse}
                    to={{
                    opacity: 1,
                    display: 'block',
                    ease: 'power2.inOut',
                    duration: 1
                    }}
                >
                    <div className={styles.overlayContainer}>

                    <div className={styles.overlayTranslate}>
                        
                    <div className={page == 1? styles.showOverlayContent: styles.hideOverlayContent}>
                        <div className={styles.overlayHeadlineContainer}>
                                <div className={styles.overlayHeadline} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.0.title', {interpolation: {escapeValue: false}})}}>
                                    
                                </div>
                                <div className={styles.padding15}>
                                    <button onClick={() => { setOverlay(false) }} style={{display: 'inline-block', background: 'none', padding: '5px 14px 5px 14px', border: '2px solid #fff', borderRadius: '12px', color: '#fff', fontFamily: 'Monserrat-SemiBold', fontSize: '12px', cursor: 'pointer'}} >
                                        { i18next.t('experts.closeBtn') }
                                    </button>
                                </div>
                        </div>
                        <div className={styles.overlayText}>
                            <div className={styles.overlayTextLeft} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.0.description', {interpolation: {escapeValue: false}})}}>
                            
                            </div>
                            <div className={styles.overlayTextRight} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.0.description2', {interpolation: {escapeValue: false}})}}>
                            
                            </div>
                        </div>
                    </div>
                    <div className={page == 2? styles.showOverlayContent: styles.hideOverlayContent}>
                        <div className={styles.overlayHeadlineContainer}>
                                <div className={styles.overlayHeadline} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.1.title', {interpolation: {escapeValue: false}})}}>
                                    
                                </div>
                                <div className={styles.padding15}>
                                    <button onClick={() => { setOverlay(false) }} style={{display: 'inline-block', background: 'none', padding: '5px 14px 5px 14px', border: '2px solid #fff', borderRadius: '12px', color: '#fff', fontFamily: 'Monserrat-SemiBold', fontSize: '12px', cursor: 'pointer'}} >
                                        { i18next.t('experts.closeBtn') }
                                    </button>
                                </div>
                        </div>
                        <div className={styles.overlayText}>
                            <div className={styles.overlayTextLeft} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.1.description', {interpolation: {escapeValue: false}})}}>
                            
                            </div>
                            <div className={styles.overlayTextRight} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.1.description2', {interpolation: {escapeValue: false}})}}>

                            </div>
                        </div>
                    </div>
                    <div className={page == 3? styles.showOverlayContent: styles.hideOverlayContent}>
                        <div className={styles.overlayHeadlineContainer}>
                                <div className={styles.overlayHeadline} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.2.title', {interpolation: {escapeValue: false}})}}>
                                    
                                </div>
                                <div className={styles.padding15}>
                                    <button onClick={() => { setOverlay(false) }} style={{display: 'inline-block', background: 'none', padding: '5px 14px 5px 14px', border: '2px solid #fff', borderRadius: '12px', color: '#fff', fontFamily: 'Monserrat-SemiBold', fontSize: '12px', cursor: 'pointer'}} >
                                        { i18next.t('experts.closeBtn') }
                                    </button>
                                </div>
                        </div>
                        <div className={styles.overlayText}>
                            <div className={styles.overlayTextLeft} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.2.description', {interpolation: {escapeValue: false}})}}>
                            
                            </div>
                            <div className={styles.overlayTextRight} dangerouslySetInnerHTML={{__html: i18next.t('experts.content.2.description2', {interpolation: {escapeValue: false}})}}>
                            
                            </div>
                        </div>
                    </div>


                        <div>
                            <hr style={{width: '100%', marginTop: '50px', marginBottom: '50px'}} />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: '1200px', padding: '10px', margin: '0 auto'}}>
                            <div onClick={() => { changeTopic('backward') }} style={{marginRight: '20px', cursor: 'pointer'}}>
                                <img src={require('../assets/icons/arrow_backward.png')} style={{width: '50px'}} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <div className={page == 1? styles.greenBubble: styles.inactiveBubble} style={{marginRight: '10px'}}></div>
                                <div className={page == 2? styles.greenBubble: styles.inactiveBubble} style={{marginRight: '10px'}}></div>
                                <div className={page == 3? styles.greenBubble: styles.inactiveBubble}></div>
                            </div>
                            <div onClick={() => { changeTopic('forward') }} style={{marginLeft: '20px', cursor: 'pointer'}}>
                                <img src={require('../assets/icons/arrow_forward.png')} style={{width: '50px'}} />
                            </div>
                        </div>

                        </div>
                    </div>

                </Tween>

            </div>
            );
    }
    
export default withTranslation()(Experts);
    