import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger, Reveal } from 'react-gsap';
import { gsap } from 'gsap';

import Navigation from './navigation';
import tracking from './tracking/tracking'; 

import styles from '../styles/numbers.module.scss'; 
import '../styles/fonts.scss';

import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg'; 
import {ReactComponent as ArrowGreenSVG} from '../assets/icons/arrow_green.svg'; 
import {ReactComponent as ArrowRedSVG} from '../assets/icons/arrow_red.svg'; 


const Numbers=()=> {
    const [change, setChange] = useState(true);
    const urlParams = useParams();
    const arrowRef = useRef();
    const navigateDown = i18next.t('anchors.7.route');

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true}); 
    },[]);

        return (
            <div className={styles.backgroundNumbers}>

            <div className={styles.numbersContainer}>

            
            <div className={styles.downloadContainer}>
                { /* <span style={{paddingRight: '3px'}}>Kennzahlen</span> */ }
                <a href={i18next.t('numbers.3.downloadLink')} onClick={() => { tracking('kennzahlenDownload') }} target="_blank" className={styles.downloadNumbersLink} dangerouslySetInnerHTML={{__html: i18next.t('numbers.3.download', {interpolation: {escapeValue: false}})}}></a>
            </div> 

            <div id="numbers" className={styles.numbers}> 

            <div className={styles.numbersSection}>
                    <div className={styles.imageContainer}>
                        <img src={require('../assets/images/umsatz.png')} style={{width: '100%'}} />
                    </div>
                    <div>
                        <div className={styles.sectionTopHeading}>{ i18next.t('numbers.0.topic') }</div>
                        <div className={styles.numbersDevelopmentContainer}>
                            <div className={styles.currencyContainer}>
                                
                                <Reveal repeat>
                                <Tween
                                to={{
                                    count: {
                                    value: i18next.t('numbers.0.mainNumber'),
                                    /*// @ts-ignore */
                                    format: () => value => value.toFixed(1).replace(".", ","),
                                    },
                                }}
                                ease="none"
                                duration={2}
                                >
                                <div>10,00</div>
                                </Tween>
                                </Reveal>

                              

                            </div>
                            <div className={styles.displayFlex}>
                                <div style={{paddingRight: '8px'}}><ArrowRedSVG style={{width: '25px'}} /></div>
                                <div className={styles.fontSizePercent}>{ i18next.t('numbers.0.change') }</div>
                            </div>
                        </div>
                        <div className={styles.textLeftAlign}>{ i18next.t('numbers.0.subHeader') }</div>
                        <div className={styles.animatedBarsContainer}>
                            <div className={styles.width100}>
                            <Reveal repeat>
                                <Tween to={{ width: '100%', }} duration={1} ease="linear">
                                    <div className={styles.topGrowthBar}>
                                        <div className={styles.yearFormat}>{ i18next.t('numbers.0.prevYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px', color: '#FFF', fontFamily: 'Monserrat-SemiBold'}}>{ i18next.t('numbers.0.prevYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                            <div className={styles.paddingTop8px}>
                            <Reveal repeat>
                                <Tween to={{ width: '96%', }} delay={0.3} duration={1} ease="linear">
                                    <div className={styles.bottomGrowthBar}>
                                        <div className={styles.yearFormatBottom}>{ i18next.t('numbers.0.currentYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px'}}>{ i18next.t('numbers.0.currentYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.numbersSection}>
                    <div className={styles.imageContainer}>
                        <img src={require('../assets/images/mitarbeiter.png')} style={{width: '100%'}} />
                    </div>
                    <div>
                        <div className={styles.sectionTopHeading}>{ i18next.t('numbers.1.topic') }</div>
                        <div className={styles.numbersDevelopmentContainer}>
                            <div className={styles.currencyContainer}>
                            <Reveal repeat>
                                <Tween
                                    to={{
                                        count: i18next.t('numbers.1.mainNumber'),
                                        /*// @ts-ignore */
                                        format: () => value => value.toFixed(1),
                                    }}
                                    ease="none"
                                    duration={2}
                                    >
                                    <div>0.0</div>
                                </Tween>
                            </Reveal>
                            </div>
                            <div className={styles.displayFlex}>
                                <div style={{paddingRight: '8px'}}><ArrowRedSVG style={{width: '25px'}} /></div>
                                <div className={styles.fontSizePercent}>{ i18next.t('numbers.1.change') }</div>
                            </div>
                        </div>
                        <div className={styles.textLeftAlign}>{ i18next.t('numbers.1.subHeader') }</div>
                        <div className={styles.animatedBarsContainer}>
                            <div className={styles.width100}>
                            <Reveal repeat>
                                <Tween to={{ width: '100%', }} duration={1} ease="linear">
                                    <div className={styles.topGrowthBar}>
                                        <div className={styles.yearFormat}>{ i18next.t('numbers.1.prevYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px', color: '#FFF', fontFamily: 'Monserrat-SemiBold'}}>{ i18next.t('numbers.1.prevYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                            <div className={styles.paddingTop8px}>
                            <Reveal repeat>
                                <Tween to={{ width: '91%', }} delay={0.3} duration={1} ease="linear">
                                    <div className={styles.bottomGrowthBar}>
                                        <div className={styles.yearFormatBottom}>{ i18next.t('numbers.1.currentYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px'}}>{ i18next.t('numbers.1.currentYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.numbersSection}>
                    <div className={styles.imageContainer}>
                        <img src={require('../assets/images/kundenzufriedenheit.png')} style={{width: '100%'}} />
                    </div>
                    <div>
                        <div className={styles.sectionTopHeading}>{ i18next.t('numbers.2.topic') }</div>
                        <div className={styles.numbersDevelopmentContainer}>
                            <div className={styles.currencyContainer}>
                            <Reveal repeat>
                                <Tween
                                to={{
                                    count: {
                                    value: i18next.t('numbers.2.mainNumber'),
                                    /*// @ts-ignore */
                                    format: () => value => value.toFixed(1).replace(".", ","),
                                    },
                                }}
                                ease="none"
                                duration={2}
                                >
                                <div>0,0</div>
                                </Tween>
                            </Reveal>
                                <div style={{paddingLeft: '10px'}}>{ i18next.t('numbers.2.mainNumberEnd') }</div>
                            </div>
                            <div className={styles.displayFlex}>
                                <div style={{paddingRight: '8px'}}><ArrowGreenSVG style={{width: '25px'}} /></div>
                                <div className={styles.fontSizePercent}>{ i18next.t('numbers.2.change') }</div>
                            </div>
                        </div>
                        <div className={styles.textLeftAlign}>{ i18next.t('numbers.2.subHeader') }</div>
                        <div className={styles.animatedBarsContainer}>
                            <div className={styles.width100}>
                            <Reveal repeat>
                                <Tween to={{ width: '95%', }} duration={1} ease="linear">
                                    <div className={styles.topGrowthBar}>
                                        <div className={styles.yearFormat}>{ i18next.t('numbers.2.prevYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px', color: '#FFF', fontFamily: 'Monserrat-SemiBold'}}>{ i18next.t('numbers.2.prevYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                            <div className={styles.paddingTop8px}>
                            <Reveal repeat>
                                <Tween to={{ width: '100%', }} delay={0.3} duration={1} ease="linear">
                                    <div className={styles.bottomGrowthBar}>
                                        <div className={styles.yearFormatBottom}>{ i18next.t('numbers.2.currentYear') }</div>
                                        <Tween to={{ opacity: '1' }} delay={1} duration={1} ease="linear"><div style={{opacity: '0', paddingRight: '5px'}}>{ i18next.t('numbers.2.currentYearResult') }</div></Tween>                       
                                    </div>
                                </Tween>
                            </Reveal>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div className={styles.bottomDisclaimerContainer}>
                    <div style={{paddingRight: '4%'}}>
                        {/*// @ts-ignore */}
                        <HashLink onClick={() => { tracking('arrowDownZahlen') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                    </div>
                    <div style={{fontFamily: 'Monserrat-Regular', fontSize: '11px', lineHeight: '17px', textAlign: 'left'}} dangerouslySetInnerHTML={{__html: i18next.t('numbers.3.bottomText', {interpolation: {escapeValue: false}})}}>
                        
                    </div>
            </div>
            
            </div>

            </div>
            );
    }
    
export default withTranslation()(Numbers);
    