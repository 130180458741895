import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import smoothscroll from 'smoothscroll-polyfill';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import Navigation from './navigation';
import Start from './start';
import Consulting from './consulting';
import Logistik from './logistik';
import Elektronik from './elektronik';
import Engineering from './engineering';
import It from './it';
import Medien from './medien';
import Sustainability from './sustainability';
import Numbers from './numbers';
import Experts from './experts';
import Report2020 from './report2020';
import Footer from './footer';
import Form from './form';

import styles from '../styles/main.module.scss'; 
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import ScrollSpy from "react-ui-scrollspy";
import MediaQuery from 'react-responsive';
import TagManager from 'react-gtm-module';

function setViewLanguage(params: any) {

    i18next.changeLanguage(params);

}

const Main=()=> {
    const [change, setChange] = useState(true);
    const [scrolledDown, setScrollDown] = useState(false);
    const urlParams = useParams();
    const tagManagerArgs = {
        gtmId: 'GTM-PRM9G5K',
        dataLayer: {
            js: new Date(),
            config: 'GTM-PRM9G5K'
        }
    }
    // @ts-ignore
    const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
    let navClassName: string;

    TagManager.dataLayer(tagManagerArgs);

    smoothscroll.polyfill();
    gsap.registerPlugin(ScrollToPlugin);

    const expertsRef = useRef();
    const startRef = useRef();
    var prevView = '#start';
    var nextView = '#experts';


    window.onscroll = function() {
        const nav = document.getElementById('navigation');
        const activeNavElement = document.getElementsByClassName('active-scroll-spy');
        if ( window.pageYOffset > 100 ) {
            setScrollDown(true);
            // @ts-ignore
            document.getElementById("starReport").style.display = "inline-block";
            
            if(activeNavElement.length > 0) {
                // @ts-ignore
                activeNavElement[0].style["text-decoration-color"] = '#87bd40';
            }
        } else {
            setScrollDown(false);
            // @ts-ignore
            document.getElementById("starReport").style.display = "none";
            
            if(activeNavElement.length > 0) {
                // @ts-ignore
                activeNavElement[0].style["text-decoration-color"] = '#000';
            }
        }
    }

    useEffect(() => {
        setViewLanguage(urlParams.lang)
        //gsap.to([startRef.current], { rotation: "+=360" });
    },[]);

        return (
            <div className={styles.backgroundLight}>
            {/*// @ts-ignore */}
            <ScrollContainer>
                <ScrollSpy>
                <div id="navigation" style={{position: 'fixed', top: '0', width: '100%', paddingBottom: '15px', zIndex: '999999999'}}>
                    <Navigation />
                    <MediaQuery minWidth={810}>
                        <div className={`${scrolledDown? styles.navigationScrolled: styles.navigationTop}`}></div>
                    </MediaQuery>
                    <MediaQuery maxWidth={809}>
                        <div className={`${scrolledDown? styles.navigationScrolled: styles.navigationTop}`}></div>
                    </MediaQuery>
                </div>
                {/*// @ts-ignore */}
                <div id="start" ref={startRef} style={{zIndex: '9999999999'}}>
                    <Start />
                </div>
                {/*// @ts-ignore */}
                <div id="consulting">
                    <Consulting />
                    <div id="elektronik"><Elektronik /></div>
                    <div id="engineering"><Engineering /></div>
                    <div id="it"><It /></div>
                    <div id="logistik"><Logistik /></div>
                    <div id="medien"><Medien /></div>
                </div>
                <div id="numbers">
                    <Numbers />
                </div>
                <div id="experts">
                    <Experts />
                </div>
                <div id="sustainability">
                    <Sustainability />
                </div>
                <div id="report2020">
                    <Report2020 />
                </div>
                <div id="footer">
                    <Footer />
                </div>
                </ScrollSpy>
                </ScrollContainer>
            </div>
            );
    }
    
export default withTranslation()(Main);
    