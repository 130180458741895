import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { withTranslation } from 'react-i18next';
import CookieBot from 'react-cookiebot';
import TagManager from 'react-gtm-module'

import Main from './components/main';

const domainGroupId = 'c1f33959-abe1-4a5e-80d4-d020ba348882';

const tagManagerArgs = {
  gtmId: 'GTM-PRM9G5K',
}

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="App">
        <CookieBot domainGroupId={domainGroupId} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/:lang" element={<Main />} />
          
        </Routes>
        
    </div>
  );
}

export default App;
