import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { ScrollContainer, ScrollPage, Animator, Fade, FadeIn, StickyIn, ZoomIn, Move, MoveOut, MoveIn, batch } from 'react-scroll-motion';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Controls, PlayState, Tween, ScrollTrigger } from 'react-gsap';
import Navigation from './navigation';
import tracking from './tracking/tracking'; 
import Appointment from './appointment';
import { gsap, wrapYoyo } from 'gsap';

import styles from '../styles/consulting.module.scss'; 
import {ReactComponent as ConsultingSVG} from '../assets/images/03_Consulting.svg';
import {ReactComponent as ArrowSVG} from '../assets/icons/bouncing_arrow.svg';  

function ExternalLink(url:any) {
    window.open(url, '_blank')
}

const Consulting=()=> {
    const [change, setChange] = useState(true);
    const [overlayValue, setOverlay] = useState(false);
    const [appointmentOverlay, setAppointment] = useState(false);
    const urlParams = useParams();
    const arrowRef = useRef();

    useEffect(() => {
        gsap.to([arrowRef.current], {duration: 1, y: -20, repeat: -1, yoyo: true}); 
    },[]);

    useLayoutEffect(()=> {
        gsap.timeline({
            scrollTrigger: {
              trigger: "#section-trigger_consulting",
              start: "center bottom-=100px",
              end: "center-=100px center",
              scrub: 3,
            //   markers: {startColor: "green", endColor: "red", fontSize: "12px"}
            }
          })
          .from("#Wand_vorne_links, #Waende, #Trennwand, #Glasfront, #Glasfront-2", { y: '-350px', opacity: 0, stagger: 0.2 })
          .from("#Arbeitsecke_links, #Sitzgruppe_rechts, #Tisch, #Bildschirm, #Elemente_rechts, #Pflanze_rechts, #Pflanze_links", { y: '-350px', opacity: 0, stagger: 0.5, delay: 2 })
          .from("#Kugelbusch, #Baeume, #Roboter, #Fahrrad", { y: '-350px', opacity: 0, stagger: 0.2 })
    }, []);

    const navigateDown = i18next.t('anchors.1.route');
    

        return (
            <div className={`${styles.backgroundExperts}`}>
            
                <div className={styles.content}>    
                    <div className={styles.contentTextBlock}>
                        <div className={styles.sectionImage} id="section-trigger_consulting">
                            <ConsultingSVG />    
                        </div>
                        <div className={styles.sectionDescription}>
                            <h1 className={styles.sectionDescriptionHeadline} style={{marginTop: '0'}} dangerouslySetInnerHTML={{__html: i18next.t('consulting.title', {interpolation: {escapeValue: false}})}}></h1>
                            <div className={styles.sectionDescriptionText}>
                                <div dangerouslySetInnerHTML={{__html: i18next.t('consulting.description', {interpolation: {escapeValue: false}})}}></div>
                                
                            </div>
                        </div>

                    </div>
                    <div className={styles.sectionNavigation}>
                        <div className={styles.btnBouncing}>
                            {/*// @ts-ignore */}
                            <HashLink onClick={() => { tracking('arrowDownConsulting') }} smooth to={navigateDown} ><ArrowSVG ref={arrowRef} className={styles.arrowDown} /></HashLink>
                        </div>
                        <div className={styles.sectionNavigationRight}>
                            <div>
                                <div>
                                    { /*<button className={styles.actionBtn} onClick={() => { ExternalLink(i18next.t('consulting.link')) }} >{ i18next.t('consulting.button') }</button>*/ }
                                    <a href={i18next.t('consulting.link')} onClick={() => { tracking('consultingLink') }} target="_blank" className={styles.actionBtn}>{ i18next.t('consulting.button') }</a>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <button className={styles.actionBtn} onClick={() => { setAppointment(true); tracking('consultingTermin') }} >{ i18next.t('consulting.buttonAppointment') }</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={appointmentOverlay? styles.show: styles.hide} style={{position: 'absolute', bottom: '190px', right: '10px', background: '#FFF', borderRadius: '12px', zIndex: '9999999'}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '5px'}}>
                                <button className={styles.closeBtn} onClick={() => { setAppointment(false) }} ><span style={{fontSize: '18px'}}>X</span></button>
                            </div>
                            <Appointment section="Consulting" />
                    </div>                

                </div>

        </div>
            );
    }
    
export default withTranslation()(Consulting);
    