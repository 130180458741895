import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_deDE from "./translations/de-DE/common.json";
import common_enUK from "./translations/en-UK/common.json";
import common_esES from "./translations/es-ES/common.json";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'de',
    debug: true,
    interpolation: { escapeValue: false },
    resources: {
      enUK: {
            translations: common_enUK
      },
      en: {
            translations: common_enUK
      },
      deDE: {
            translations: common_deDE
      },
      de: {
            translations: common_deDE
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });


export default i18n;