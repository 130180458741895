import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

import Navigation from './navigation';
import tracking from './tracking/tracking'; 

import styles from '../styles/social.module.scss'; 



const Social=(props: any)=> {
    const [change, setChange] = useState(true);
    const urlParams = useParams();
    const sectionVar = props.section;

    const socialNavigation: { id: number, name: string, external: string }[] = i18next.t('socialNavigation', {returnObjects:true});
    
        return (
            <div>
                <div>
                {
                    socialNavigation.map(({id, name, external}: any) => <a href={external} onClick={() => { tracking(name) }} key={id} target='_blank'><img src={require(`../assets/icons/${name}${sectionVar}.png`)} width="auto" height="25px" style={{height: '25px', width: 'auto', margin: '0 0 0 15px'}} /></a>)
                }
                </div>
            </div>
            );
    }
    
export default withTranslation()(Social);
    