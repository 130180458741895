import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, useParams, Link } from "react-router-dom";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Suspense } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import MediaQuery from 'react-responsive'
import { FaBars, FaChevronDown, FaGlobe } from 'react-icons/fa';
import tracking from './tracking/tracking'; 

import styles from '../styles/navigation.module.scss'; 
import '../styles/fonts.scss'; 

function setViewLanguage(params: any) {

    i18next.changeLanguage(params);

    console.log(params)
}

const Navigation=()=> {
    const [change, setChange] = useState(true);
    const [menuValue, setMenu] = useState(false);	
    const urlParams = useParams();

    const navigation: { id: number, name: string, link: string }[] = i18next.t('navigation', {returnObjects:true});
    const secondaryNavigation: { id: number, name: string, external: string }[] = i18next.t('secondaryNavigation', {returnObjects:true});
    const availableLanguages: { id: number, name: string, route: string }[] = i18next.t('availableLanguages', {returnObjects:true});
    const currentLang = i18next.languages[0];

        return (
            <div>
            <MediaQuery minWidth={810}>
            <div className={styles.navigationDimensions}>

                    <div className={styles.navigationContainer}>
                    
                    <div style={{display: 'flex', zIndex: '9999999', marginRight: '40px'}}>
                        <div style={{background: '#000'}}>
                            <HashLink onClick={() => { tracking('starlogo') }} smooth to='#start' ><img src={require('../assets/logos/180926_STAR_kurz_weiss.png')} width="115px" height="32px" style={{width: '115px', padding: '33px 13px 8px 13px'}} /></HashLink>
                        </div>
                        <div>
                            <div id="starReport" className={styles.starReport}>
                            { i18next.t('title') }<br />
                            <span>{ i18next.t('titleSub') }</span>
                            </div>
                        </div>
                    </div>

                    <div style={{color: '#000'}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', paddingBottom: '15px'}}>

                            {
                                availableLanguages.map(({id, name, route, trackingId}: any) => <button onClick={() => { setViewLanguage(route); tracking(trackingId) }} key={id} style={{marginLeft: '5px', padding: '0 0 0 5px', color: '#000', fontSize: '14px', background: 'none', border: 'none', cursor: 'pointer'}} className={`${currentLang==route? styles.activeLang: styles.defaultLang}`} >{name}</button>)

                            }

                            </div>
                            <div className={styles.navigationHashLink} style={{display: 'flex', justifyContent: 'end', width: '100%', flexDirection: 'row'}}>

                            {
                                navigation.map(({id, name, route, container, trackingId}: any) => <div key={id} style={{paddingBottom: '0px', paddingLeft: '40px'}}><HashLink onClick={() => { tracking(trackingId) }} smooth data-to-scrollspy-id={container} to={'#'+container} style={{color: '#000', fontFamily: 'Monserrat-Regular', fontSize: '14px'}} >{name}</HashLink></div>)

                            }

                            {
                                secondaryNavigation.map(({id, name, route, container, external, trackingId}: any) => <div key={id} style={{paddingBottom: '0px', paddingLeft: '40px'}}><a href={external} onClick={() => { tracking(trackingId) }} target="_blank" style={{color: '#000', fontFamily: 'Monserrat-Regular', fontSize: '14px'}} >{name}</a></div>)

                            }

                            </div>
                        </div>
                    </div>
                    
                    </div>
                    <div>
                        
                    </div>                    
                        
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={809}>
                <div style={{display: 'flex', width: '100%', height: '60px', justifyContent: 'space-between', margin: '0 auto', color: '#000'}}>              
                            <div style={{background: '#000', marginLeft: '8px', zIndex: '9999999'}}>
                                <HashLink onClick={() => { tracking('starlogo') }} smooth to='#start' ><img src={require('../assets/logos/180926_STAR_kurz_weiss.png')} width="110px" height="31px" style={{width: '110px', padding: '23px 10px 10px 10px'}} /></HashLink>
                            </div>
                            <div onClick={() => { setMenu(true) }} style={!menuValue? {display: 'block', zIndex: '9999999'}: {display: 'none', zIndex: '9999999'}}>
                                <FaBars style={{fontSize: '35px', padding: '20px 10px 10px 10px'}} />
                            </div>
                            <div onClick={() => { setMenu(false) }} style={menuValue? {display: 'block', zIndex: '9999999'}: {display: 'none', zIndex: '9999999'}}>
                                <FaChevronDown style={{fontSize: '35px', padding: '20px 10px 10px 10px'}} />
                            </div>
                </div>

                <div className={`${menuValue? styles.displayBlock: styles.displayNone}`} style={{background: '#fff', marginTop: '10px', borderBottom: '1px solid #000'}}>
                    <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', justifyContent: 'space-between', background: '#fff', zIndex: '999999999'}}>
                    {
                        navigation.map(({id, name, route, container, trackingId}: any) => <HashLink onClick={() => { tracking(trackingId) }} smooth data-to-scrollspy-id={container} key={id} to={'#'+container} style={{padding: '10px', color: '#000', fontFamily: 'Monserrat-Bold', fontSize: '14px'}} >{name}</HashLink>)

                    }
                    {
                        secondaryNavigation.map(({id, name, external, trackingId}: any) => <a href={external} onClick={() => { tracking(trackingId) }} target='_blank' key={id} style={{color: '#000', fontFamily: 'Monserrat-Bold', fontSize: '14px', padding: '10px'}}>{name}</a>)

                    }
                    
                    <div style={{display: 'flex', flexDirection: 'row', margin: '13px 10px 14px 0px'}}>
                        <div><FaGlobe style={{color: '#000', paddingTop: '2px'}} /></div>
                        <div>
                            {
                                availableLanguages.map(({id, name, route, trackingId}: any) => <button onClick={() => { setViewLanguage(route); tracking(trackingId) }} key={id} style={{marginLeft: '5px', padding: '0 0 0 5px', color: '#000', fontSize: '14px', background: 'none', border: 'none', cursor: 'pointer'}} className={`${currentLang==route? styles.activeLang: styles.defaultLang}`} >{name}</button>)

                            }
                        </div>
                    </div>

                    </div>
                </div>

             </MediaQuery>
             </div>
            );
    }
    
export default withTranslation()(Navigation);
    